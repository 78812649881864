import React from 'react';

export function ReportRow({ deemphasize, label, note, hours, maxHours }) {
  const barPercentage = maxHours ? `${(hours / maxHours) * 100}%` : undefined;

  const barStyle =
    label === 'Total'
      ? {}
      : {
          background: `linear-gradient(to right,
      var(--color-opposite),
      var(--color-fainter) ${barPercentage},
      var(--color-opposite) ${barPercentage}
    )`
        };

  return (
    <>
      <style>:</style>
      <tr
        className={deemphasize ? 'deemphasize' : undefined}
        style={{
          display: 'flex',
          ...barStyle
        }}
      >
        <th>
          {label}
          {note && <span className="note">{note}</span>}
        </th>
        <td className="hour-count">
          {hours}
          {/* <span className="units"> hrs</span> */}
        </td>
      </tr>
    </>
  );
}
