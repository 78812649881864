import React from 'react';
import { ProgressBar } from './ProgressBar';
import { ReportRow } from './ReportRow';

export function Report(props) {
  const {
    isInternalView,
    lastWeekStr,
    hoursThisWeek,
    hoursLastWeek,
    hoursByMonth,
    hoursTotal
  } = props.response;

  const budgetedHours = undefined;
  const budgetProgressPercentage = budgetedHours
    ? Math.round((hoursTotal / budgetedHours) * 100)
    : undefined

  const hoursRaw = hoursByMonth.map(month => parseFloat(month.hours));

  const mostHoursInAMonth = Math.max.apply(null, hoursRaw);

  return (
    <div className="App">
      <table>
        <thead>
          <tr>
            <th colSpan="2">
              <strong>Hours Report</strong>
              <span>Mihira Jayasekera</span>
            </th>
          </tr>
        </thead>

        <tbody>
          {budgetedHours && (
            <tr>
              <td colSpan="2">
                <ProgressBar
                  percentComplete={budgetProgressPercentage}
                  label={`
                  ${Math.round(
                    hoursTotal
                  )} of ${budgetedHours.toFixed()} budgeted hrs
                  (${budgetProgressPercentage}%) ·
                  ${Math.max(
                    budgetedHours - hoursTotal,
                    0
                  ).toFixed()} hrs remaining
                `}
                />
              </td>
            </tr>
          )}

          {isInternalView && (
            <ReportRow
              hours={hoursThisWeek}
              label={'This week'}
              note={'starting Monday'}
              maxHours={mostHoursInAMonth}
            />
          )}

          <ReportRow
            hours={hoursLastWeek}
            label={'Last week'}
            note={lastWeekStr}
            maxHours={mostHoursInAMonth}
          />

          {hoursByMonth.reverse().map((month, index) => (
            <ReportRow
              key={index}
              hours={month.hours}
              label={month.label}
              note={month.note}
              deemphasize={index > 0}
              maxHours={mostHoursInAMonth}
            />
          ))}

          <ReportRow hours={hoursTotal} label={'Total'} />
        </tbody>
      </table>
    </div>
  );
}
