import React, { useEffect, useState } from 'react';
import { ErrorMsg } from './components/ErrorMsg';
import { Report } from './components/Report';
import { Spinner } from './components/Spinner';
import './App.css';

function App() {
  const [response, setResponse] = useState({});
  const [fetchStatus, setFetchStatus] = useState('loading');

  useEffect(() => {
    fetch('.netlify/functions/get-timesheet-data/?projectId=37486919')
      .then(response => response.json())
      .then(data => {
        setResponse(data);
        setFetchStatus('loaded');
      })
      .catch(error => {
        setFetchStatus('error');
      });
  }, []);

  if (fetchStatus === 'loading') {
    return <Spinner />;
  } else if (fetchStatus === 'loaded') {
    return <Report response={response} />;
  } else {
    return <ErrorMsg />;
  }
}

export default App;
