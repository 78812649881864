import React from 'react';

export function ErrorMsg() {
  return (
    <div className="error">
      <h1>
        <span role="img" aria-label="Uh-oh.">
          🙁
        </span>
        Sorry, there was a problem retrieving timesheet data.
      </h1>

      <p>(Please let Mihira know.)</p>
    </div>
  );
}
