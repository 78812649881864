import React from 'react';

export function ProgressBar(props) {
  const { percentComplete, label } = props;

  const percentCompleteCapped =
    percentComplete < 0 ? 0 : percentComplete > 100 ? 100 : percentComplete;

  return (
    <div className="progress-bar">
      <div className="track">
        <div
          className={`progress${percentComplete < 100 ? '' : ' complete'}`}
          style={{
            width: `${percentCompleteCapped}%`
          }}
        ></div>
      </div>
      <p>{label}</p>
    </div>
  );
}
